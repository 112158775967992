html, body, .hero-container {
    height: 100% !important;
}

.hero {
    background: url('../img/bg.jpg') center;
    background-size: cover;
    width: 100%;
    height: 90%;
    min-height: 260px;
    color: #fff;
    .hero-container {
        width: 100%;
        min-height: 100%;
        background: rgba(104, 0, 48, 0.70);
        background: linear-gradient(-45deg, rgba(110, 0, 37, 0.88) 0%, rgba(75, 0, 110, 0.45) 100%);
        .container {
            height: 100%;
            .logo-link {
                position: absolute;
                margin-top: 25px;
                width: 45px;
                height: 40px;
            }
            .hero-text-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                .hero-text {
                    text-align: center;
                    h1 {
                        font-weight: 700;
                        font-size: 72px;
                    }
                    p.subtitle {
                        font-size: 36px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.signup-form-container {
    padding-top: 150px;
    padding-bottom: 150px;
}

label {
    font-weight: 500;
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 75px;
}

.submit-text {
    padding-left: 35px;
    padding-right: 35px;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: @btn-primary-border;
    border-color: @btn-primary-border;
    color: #fff;
}

input:focus {
    box-shadow: none !important;
    border-color: #870042 !important;
}

.logo-link {
    position: absolute;
    margin-top: 25px;
    width: 45px;
    height: 40px;
}

.mentor-select {
    h1 {
        margin-top: 150px;
        font-size: 48px;
        font-weight: 600;
    }
    h2 {
        font-size: 30px;
        font-weight: 500;
    }
    .no-bottom-margin {
        margin-bottom: 0;
    }
    .no-top-margin {
        margin-top: 0;
    }
    .mentor {
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 50px;
        margin-bottom: 50px;
        &.mentor-selected {
            box-shadow: 4px 0 38px rgba(135, 0, 66, .1), 1px 0 8px rgba(135, 0, 66, .05);
        }
        .mentor-photo, .mentor-gif {
            width: 100%;
        }
        .mentor-info {
            padding: 15px;
            .mentor-name {
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 0;
            }
            .mentor-curriculum {
                font-size: 16px;
            }
            .mentor-description {
                font-size: 18px;
                margin-bottom: 0;
            }
            .gif-btn {
                cursor: pointer;
                font-size: 16px;
            }
            .mentor-quote {
                font-size: 16px;
                margin-top: 12.5px;
            }
        }
    }
    .mentors {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.loading-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: .5rem solid #e8e8e9;
    border-top-color: #818182;
    animation: spin 0.4s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.text-small {
    font-size: @font-size-small;
    margin-top: @grid-gutter-width;
}
.has-error {
    input.form-control {
        border-color: #870042;
    }
    label.control-label {
        color: #870042;
    }
}

@media (max-width: @screen-sm-max) {
    .hero-text {
        h1 {
            font-size: 1.5em !important;
        }
        p.subtitle {
            font-size: 0.8em !important;
        }
    }
}