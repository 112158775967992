@bootstrap-root: "../../../bower_components/bootstrap/less/";

@import '@{bootstrap-root}variables.less';
@import '@{bootstrap-root}mixins.less';
@import "variables.less";

// Reset and dependencies
@import '@{bootstrap-root}normalize.less';
@import "@{bootstrap-root}print.less";
@import "@{bootstrap-root}utilities.less";

// Core CSS
//@import "@{bootstrap-root}alerts.less";
//@import "@{bootstrap-root}glyphicons.less";
@import "@{bootstrap-root}grid.less";
@import "@{bootstrap-root}scaffolding.less";
@import "@{bootstrap-root}forms.less";
@import "@{bootstrap-root}responsive-utilities.less";

@import "@{bootstrap-root}type.less";
///@import "@{bootstrap-root}tables.less";
@import "@{bootstrap-root}buttons.less";
@import "@{bootstrap-root}button-groups.less";

//@import "@{bootstrap-root}dropdowns.less";
//@import "@{bootstrap-root}breadcrumbs.less";
@import "@{bootstrap-root}component-animations.less";
//@import "@{bootstrap-root}navs.less";
//@import "@{bootstrap-root}navbar.less";
//@import "@{bootstrap-root}labels.less";
//@import "@{bootstrap-root}modals.less";
//@import "@{bootstrap-root}badges.less";
@import "@{bootstrap-root}carousel.less";
//@import "@{bootstrap-root}panels.less";
//@import "@{bootstrap-root}wells.less";
//@import "@{bootstrap-root}popovers.less";
//@import "@{bootstrap-root}tooltip.less";
//@import "@{bootstrap-root}close.less";


// Custom
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.6.0/css/font-awesome.min.css";
@import "custom.less";

