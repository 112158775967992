@body-bg: #FFF;
@icon-font-path: "//maxcdn.bootstrapcdn.com/bootstrap/3.3.1/fonts/";
@fa-font-path: "//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/fonts";
// @border-radius-base: 0;
// @border-radius-small: 0;
@font-family-base: 'Raleway', sans-serif;
@font-size-base: 32px;

// Text
@font-size-h1: 27pt;
@text-color: #1d1d1d;
@text-muted: #656565;
@font-size-small: 10pt;

// Buttons
@btn-primary-bg: transparent;
@btn-primary-border: #870042;
@btn-primary-color: @btn-primary-border;



// Links
@link-color: @btn-primary-border;
@link-hover-color: #6d0036;

@grid-gutter-width: 80px;
